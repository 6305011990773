import * as _functionBind2 from "function-bind";
var _functionBind = _functionBind2;
try {
  if ("default" in _functionBind2) _functionBind = _functionBind2.default;
} catch (e) {}
import * as _type2 from "es-errors/type";
var _type = _type2;
try {
  if ("default" in _type2) _type = _type2.default;
} catch (e) {}
import _functionCall from "./functionCall";
import _actualApply from "./actualApply";
var exports = {};
var bind = _functionBind;
var $TypeError = _type;
var $call = _functionCall;
var $actualApply = _actualApply;

/** @type {import('.')} */
exports = function callBindBasic(args) {
  if (args.length < 1 || typeof args[0] !== "function") {
    throw new $TypeError("a function is required");
  }
  return $actualApply(bind, $call, args);
};
export default exports;